.add-student-button { 
    background-color: rgb(105, 224, 105);
    color: white;
    border-radius: 50%; 
    &:hover { 
        cursor: pointer;
        color: rgb(10, 10, 10); 
        background-color: rgb(70, 190, 70);
        transform: scale(1.08);
        transition: .2s ease-in;
    }
}