.save-button {
  margin-left: 10px;
  background-color: #9fec9f;
  border-radius: 50%;
  color: #080808;
  padding: 2px;
}

.save-button:hover {
  background-color: #36ca36;
  color: white;
  cursor: pointer;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  -webkit-transition: .2s ease-in;
  transition: .2s ease-in;
}
