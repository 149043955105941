.preview-button { 
    background-color: rgb(132, 180, 224);
    color: black; 
    border-radius: 25px;
    margin-right: 20px;
    padding: 2px;
    &:hover { 
        background-color: rgb(43, 144, 240);
        color: white; 
        cursor: pointer;
        transform: scale(1.08);
        transition: .2s ease-in;
    }
}