@import "../../colors"; 

.student-list-wrapper { 
  position: relative;
    width: 320px;
    min-width: 280px; 
    max-height: 80vh; 
    min-height: 1060px;
    margin-left: 10px;
    border-radius: 10px;
    padding: 10px;
    padding-right: 20px;
    background-color: $mainGrayBoxColor;
    border: 2px solid black;
    overflow-y: auto;
    &::-webkit-scrollbar { 
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #d6d5d5;
      }
    &::-webkit-scrollbar-thumb {
        background: rgb(63, 62, 62);
        border-radius: 10px;
      }
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(54, 53, 53);
        cursor: pointer;
      }
}