.title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  width: 220px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.title-container .add-button {
  color: #89d689;
  border: 2px solid #89d689;
  background-color: #424040;
  border-radius: 50%;
  border: 1px solid #89d689;
  margin-top: 20px;
}

.title-container .add-button:hover {
  cursor: pointer;
  background-color: black;
  color: #b0ecb0;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
}

.project-container {
  width: 100%;
  margin-bottom: 10px;
}

.project-container .project {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 5px;
}

.project-container .project .item-name {
  padding: 5px;
  margin-left: 8px;
  width: 280px;
}

.project-container .project .delete-item:hover {
  color: #e47878;
  cursor: pointer;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}
