.add-student-button {
  background-color: #69e069;
  color: white;
  border-radius: 50%;
}

.add-student-button:hover {
  cursor: pointer;
  color: #0a0a0a;
  background-color: #46be46;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  -webkit-transition: .2s ease-in;
  transition: .2s ease-in;
}
