@import "../../colors"; 

.login-page-wrapper { 
    width: 100%; 
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../media/images/loginPageWave.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    .login-container { 
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 400px; 
        height: 500px; 
        border-radius: 20px;
        box-shadow: rgba(95, 126, 104, 0.25) 0px 14px 28px, rgba(149, 233, 168, 0.22) 0px 10px 10px;
        @include border(3px, solid, $mainGreenColor);
        .label-container { 
            display: flex;
            flex-direction: column;
            width: 100%;
            label { 
                display: flex;
                flex-direction: column;
                margin-top:10px;
            }
            input { 
                padding: 10px;
                &:focus { 
                    outline-color: $mainGreenColor;
                }
            }
            button { 
                width: 100%;
                padding: 10px;
                margin-top: 10px;
                background-color: $mainGreenColor;
                border: none;
                font-size: 20px;
                &:hover { 
                    cursor: pointer;
                    background-color: $mainGreenHoverColor;
                }
            }
        } 
    }
}