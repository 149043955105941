@import "../../../colors"; 

.student-container { 
    position: relative; 
    width: 100%; 
    height: 70px;
    border-radius: 10px;
    background-color: $mainStudentColor; 
    margin-top: 5px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    .student-name-container { 
        display: flex;
        align-items: center;
        .student-icon { 
            margin-top: 10px;
        }
        h3 { 
            margin-left: 10px;
            font-size: 14px;
        }
    }
    .student-group { 
        position: absolute; 
        top: 0px;
        border-radius: 10px 10px 0px 0px;
        height: 30%;
        width: 100%;
        display: flex;
        right: 0px;
        justify-content: center;
        align-items: center;
        background-color: $mainStudentGroupColor;
        font-size: 12px;
        font-weight: 700;
    }
    &:hover { 
        transform: scale(1.03);
        transition: .6s ease-in-out;
        border: 2px solid black;
        cursor: pointer;
    }
    .delete-button { 
        position: absolute; 
        right: -10px;
        top: -10px;
        background-color: rgb(224, 112, 112);
        padding: 3px;
        z-index: 10;
        border-radius: 10px;
        display: none;
        &:hover { 
            background-color: red;
            cursor: pointer;
            transform: scale(1.1);
            transition: .3s ease; 
        }
    }
}

.selected-student-button { 
    border: 2px solid black;
    background-color: rgb(221, 181, 108);
    .delete-button { 
        display: block;
    }
}