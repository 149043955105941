.modal-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  z-index: 10;
  top: 0;
  position: absolute;
  padding: 40px;
  overflow-y: auto;
}

.modal-wrapper .modal {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  width: 600px;
  height: 400px;
  background-color: rgba(13, 14, 13, 0.7);
}

.modal-wrapper .modal form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-wrapper .modal form input {
  margin-bottom: 5px;
  padding: 10px;
  width: 300px;
}

.modal-wrapper .modal form button {
  width: 300px;
  padding: 10px;
  background-color: #0eade0;
  border-radius: 5px;
}

.modal-wrapper .modal form button:hover {
  background-color: #98d7ec;
  cursor: pointer;
}

.modal-wrapper .modal .modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #e96565;
}

.modal-wrapper .modal .modal-close-button:hover {
  cursor: pointer;
  color: #eea5a5;
}
