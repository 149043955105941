.preview-button {
  background-color: #84b4e0;
  color: black;
  border-radius: 25px;
  margin-right: 20px;
  padding: 2px;
}

.preview-button:hover {
  background-color: #2b90f0;
  color: white;
  cursor: pointer;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  -webkit-transition: .2s ease-in;
  transition: .2s ease-in;
}
