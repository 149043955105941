@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital@1&family=Kufam:ital@1&display=swap');

* { 
  margin: 0px; 
  padding: 0px; 
  box-sizing: border-box;
}

html { 
  font-family: 'DM Sans', sans-serif;
  font-family: 'Kufam', sans-serif;
}

body { 
  width: 100%; 
  min-height: 100vh; 
}

.app { 
  width: 100%; 
  height: 100%; 
}