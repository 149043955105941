@import "../../colors"; 

.modal-wrapper { 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100%;
    background-color: rgba(1,1,1,0.4); 
    z-index: 10; 
    top: 0; 
    position: absolute;
    padding: 40px; 
    overflow-y: auto;
    .modal { 
        position: relative; 
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        width: 600px;
        height: 400px;
        background-color: rgba(13, 14, 13, 0.7);
        form { 
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            input { 
                margin-bottom: 5px;
                padding: 10px;
                width: 300px;
            }
            button { 
                width: 300px;
                padding: 10px;
                background-color: $mainBlueColor; 
                border-radius: 5px;
                &:hover { 
                    background-color: $mainBlueHoverColor;
                    cursor: pointer;
                }
            }
        }
        .modal-close-button { 
            position: absolute; 
            top: 10px;
            right: 10px; 
            color: rgb(233, 101, 101);
            &:hover { 
                cursor: pointer;
                color:rgb(238, 165, 165);
            }
        }
    }
}