.checkbox-filter-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
}

.checkbox-filter-wrapper .search-filter-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkbox-filter-wrapper .search-filter-button-container input[type=text] {
  width: 90%;
  padding: 10px;
  margin-right: 5px;
}

.checkbox-filter-wrapper .checkbox-filters {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
