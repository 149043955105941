.logout-button {
  background-color: #ce8484;
  width: 100px;
  padding: 10px;
  color: white;
  font-size: 20px;
  border-radius: 10px;
}

.logout-button:hover {
  background-color: #ec9393;
  cursor: pointer;
}
