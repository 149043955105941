.research-items h2 {
  margin-bottom: 20px;
  margin-top: 40px;
}

.research-items li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 30px;
}

.research-items li .checkmark-icon {
  color: #9cc52c;
  margin-right: 10px;
  margin-left: 30px;
  width: 25px;
  height: 25px;
}
