.dashboard-main-wrapper { 
    display: flex;
    justify-content: center;
    width: 100%; 
    min-height: calc(100vh - 100px);
    height: 100%;
    background-image: url("../../media/images/mainDashBottom.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    .rapport-main-info { 
        margin-top: 10px;
        display: flex;
    }
}