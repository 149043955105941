.personal-messages-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  padding: 10px;
  background-color: rgba(228, 228, 45, 0.3);
}

.personal-messages-wrapper .personal-messages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.personal-messages-wrapper .personal-messages textarea {
  max-width: 450px;
  min-width: 450px;
  min-height: 150px;
  max-height: 150px;
  margin: 5px;
  padding: 5px;
}

.personal-messages-wrapper .personal-messages textarea:focus {
  outline: 2px solid #00a2ff;
  border: 1px solid white;
}
