.rapport-info-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.rapport-info-wrapper .rapport-make-tool-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  max-height: 1008px;
  border: 2px solid black;
  border-radius: 10px;
  margin-left: 15px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.rapport-info-wrapper .rapport-make-tool-wrapper::-webkit-scrollbar {
  width: 5px;
}

.rapport-info-wrapper .rapport-make-tool-wrapper::-webkit-scrollbar-track {
  background: #d6d5d5;
}

.rapport-info-wrapper .rapport-make-tool-wrapper::-webkit-scrollbar-thumb {
  background: #3f3e3e;
  border-radius: 10px;
}

.rapport-info-wrapper .rapport-make-tool-wrapper::-webkit-scrollbar-thumb:hover {
  background: #363535;
  cursor: pointer;
}

.rapport-info-wrapper .rapport-name {
  text-align: center;
  width: 60%;
  background-color: #9cc52c;
  padding-bottom: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  border-radius: 10px 10px 0px 0px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.rapport-info-wrapper .title-button-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
