.title-container { 
    display: flex;
    margin-bottom: 10px;
    width: 220px;
    justify-content: space-between;
    align-items: center;
    .add-button { 
        color: rgb(137, 214, 137);
        border: 2px solid rgb(137, 214, 137);
        background-color: rgb(66, 64, 64);
        border-radius: 50%;
        border: 1px solid rgb(137, 214, 137);
        margin-top: 20px;
        &:hover { 
            cursor: pointer;
            background-color: black;
            color: rgb(176, 236, 176);
            transform: scale(1.08);
            transition: .4s ease-in-out; 
        }
    }
}
.project-container { 
    width: 100%; 
    margin-bottom: 10px;
    .project {
         display: flex;
         align-items: center;
         margin-bottom: 5px;
         .item-name { 
             padding: 5px;
             margin-left: 8px;
             width: 280px;
         }
         .delete-item { 
            &:hover { 
                color: rgb(228, 120, 120);
                cursor: pointer;
                transition: .2s ease-in-out;
            }
        }
    }
}