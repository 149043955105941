.end-page-wrapper { 
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .autograph-container { 
        display: flex;
        margin-top: 50px;
        justify-content: space-around;
        .autograph { 
            width: 300px;
            height: 150px; 
            border: 2px solid black; 
            border-radius: 10px;
        }
        h3 { 
            margin-left: 0;
        }
    }
    .next-group-wrapper { 
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .next-group { 
            width: 200px;
            height: 150px;
            border: 2px solid black; 
            border-radius: 10px;
        }
    }
}


