.navbar-wrapper {
  width: 100%;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 2px solid black;
  background-color: #e9f1eb;
  border-radius: 0px 0px 10px 10px;
}

.navbar-wrapper .navbar-logout-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 10px;
}

.navbar-wrapper .navbar-logout-section h3 {
  margin-right: 15px;
}
