.navbar-wrapper { 
    width: 100%; 
    height: 100px; 
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid black;
    background-color: rgb(233, 241, 235);
    border-radius: 0px 0px 10px 10px;
    .navbar-logout-section { 
        display: flex;
        height: 100px;
        justify-content: center;
        align-items: center;
        margin-right: 10px; 
        h3 { 
            margin-right: 15px;
        }
    }
}