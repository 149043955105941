.student-list-wrapper {
  position: relative;
  width: 320px;
  min-width: 280px;
  max-height: 80vh;
  min-height: 1060px;
  margin-left: 10px;
  border-radius: 10px;
  padding: 10px;
  padding-right: 20px;
  background-color: #ffffff;
  border: 2px solid black;
  overflow-y: auto;
}

.student-list-wrapper::-webkit-scrollbar {
  width: 5px;
}

.student-list-wrapper::-webkit-scrollbar-track {
  background: #d6d5d5;
}

.student-list-wrapper::-webkit-scrollbar-thumb {
  background: #3f3e3e;
  border-radius: 10px;
}

.student-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: #363535;
  cursor: pointer;
}
