.save-button { 
    margin-left: 10px;
    background-color: rgb(159, 236, 159);
    border-radius: 50%;
    color: rgb(8, 8, 8);
    padding: 2px;
    &:hover { 
        background-color: rgb(54, 202, 54);
        color: white; 
        cursor: pointer;
        transform: scale(1.08);
        transition: .2s ease-in;
    }
}