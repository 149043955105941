$mainGreenColor: #9cc52c; 
$mainGreenHoverColor: #cce97b; 

$mainBlueColor: #0eade0; 
$mainBlueHoverColor: #98d7ec;

$mainGrayBoxColor: #ffffff; 

$rapportBackgroundOne: rgba(228, 228, 45, 0.3); 

$rapportBackgroundTwo: rgba(60, 178, 251, 0.3);

$mainStudentColor: #9cc52c;
$mainStudentGroupColor: rgb(233, 231, 111); 

@mixin border($px, $type, $color) { 
     border: $px $type $color; 
}

