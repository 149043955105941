.dashboard-main-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  min-height: calc(100vh - 100px);
  height: 100%;
  background-image: url("../../media/images/mainDashBottom.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.dashboard-main-wrapper .rapport-main-info {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
