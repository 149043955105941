.student-container {
  position: relative;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  background-color: #9cc52c;
  margin-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 5px;
}

.student-container .student-name-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.student-container .student-name-container .student-icon {
  margin-top: 10px;
}

.student-container .student-name-container h3 {
  margin-left: 10px;
  font-size: 14px;
}

.student-container .student-group {
  position: absolute;
  top: 0px;
  border-radius: 10px 10px 0px 0px;
  height: 30%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: 0px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #e9e76f;
  font-size: 12px;
  font-weight: 700;
}

.student-container:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  -webkit-transition: .6s ease-in-out;
  transition: .6s ease-in-out;
  border: 2px solid black;
  cursor: pointer;
}

.student-container .delete-button {
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #e07070;
  padding: 3px;
  z-index: 10;
  border-radius: 10px;
  display: none;
}

.student-container .delete-button:hover {
  background-color: red;
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

.selected-student-button {
  border: 2px solid black;
  background-color: #ddb56c;
}

.selected-student-button .delete-button {
  display: block;
}
