.student-information-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 2px solid black;
  height: 100px;
  font-size: 16px;
}

.student-information-container h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.student-information-container .image {
  width: 120px;
  height: 100px;
}
