@import "../../../colors";

.personal-messages-wrapper { 
    display: flex;
    justify-content: center;
    width: 100%; 
    padding: 10px;
    background-color: $rapportBackgroundOne;
    .personal-messages { 
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        textarea { 
            max-width: 450px;
            min-width: 450px; 
            min-height: 150px;
            max-height: 150px; 
            margin: 5px;
            padding: 5px;
            &:focus { 
                outline: 2px solid rgb(0, 162, 255);
                border: 1px solid white;
            }
        }
    }
}