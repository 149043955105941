@import "../../colors"; 

.rapport-info-wrapper { 
    display: flex;
    flex-direction: column;
    align-items: center;
    .rapport-make-tool-wrapper { 
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        max-height: 1008px;
        border: 2px solid black;
        border-radius: 10px;
        margin-left: 15px;
        overflow-y: auto;
        margin-bottom: 10px;
        &::-webkit-scrollbar { 
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #d6d5d5;
          }
        &::-webkit-scrollbar-thumb {
            background: rgb(63, 62, 62);
            border-radius: 10px;
          }
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(54, 53, 53);
            cursor: pointer;
          }
    }
    .rapport-name { 
        text-align: center;
        width: 60%;
        background-color: $mainStudentColor;
        padding-bottom: 20px;
        margin-bottom: 0px;
        margin-top: 0px;
        border-radius: 10px 10px 0px 0px;
        border-top: 2px solid black;
        border-left: 2px solid black;
        border-right: 2px solid black;
    }
    .title-button-container { 
      width: 100%; 
      display: flex;
      justify-content: center;
      align-items: center;
    }
}
