.login-page-wrapper {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url("../../media/images/loginPageWave.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}

.login-page-wrapper .login-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 400px;
  height: 500px;
  border-radius: 20px;
  -webkit-box-shadow: rgba(95, 126, 104, 0.25) 0px 14px 28px, rgba(149, 233, 168, 0.22) 0px 10px 10px;
          box-shadow: rgba(95, 126, 104, 0.25) 0px 14px 28px, rgba(149, 233, 168, 0.22) 0px 10px 10px;
  border: 3px solid #9cc52c;
}

.login-page-wrapper .login-container .label-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.login-page-wrapper .login-container .label-container label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 10px;
}

.login-page-wrapper .login-container .label-container input {
  padding: 10px;
}

.login-page-wrapper .login-container .label-container input:focus {
  outline-color: #9cc52c;
}

.login-page-wrapper .login-container .label-container button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #9cc52c;
  border: none;
  font-size: 20px;
}

.login-page-wrapper .login-container .label-container button:hover {
  cursor: pointer;
  background-color: #cce97b;
}
